.carousel-fade .carousel-inner .item {
  -webkit-transition-property: opacity;
  transition-property: opacity;
}
.carousel-fade .carousel-inner .item,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  opacity: 0;
}
.carousel-fade .carousel-inner .active,
.carousel-fade .carousel-inner .next.left,
.carousel-fade .carousel-inner .prev.right {
  opacity: 1;
}
.carousel-fade .carousel-inner .next,
.carousel-fade .carousel-inner .prev,
.carousel-fade .carousel-inner .active.left,
.carousel-fade .carousel-inner .active.right {
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.carousel-fade .carousel-control {
  z-index: 2;
}

.fade-carousel {
  position: relative;
  height: 100vh;
}
.fade-carousel .carousel-inner .item {
  height: 100vh;
}

.fade-carousel .carousel-indicators{
    left: 54%;
}

.fade-carousel .carousel-indicators > li {
  margin: 0 2px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  opacity: 0.7;
  width: 15px;
  height: 15px;
}
.fade-carousel .carousel-indicators > li.active {
  width: 15px;
  height: 15px;
  opacity: 1;
}

.hero {
  position: absolute;
  left: 30%;
  top: 60%;
  z-index: 3;
  color: #fff;
  transform: translate3d(-50%, -50%, 0);
  width: 600px;
  bottom: 0;
}
.hero h1 {
  font-size: 2em;
  font-weight: bold;
  margin: 0;
  padding: 0;
}

.hero h4 {
    font-size: 1.05em;
    font-weight: 500;
    line-height: 1.5;
    margin-bottom: 1.3em;
}

.fade-carousel .carousel-inner .item .hero {
  opacity: 0;
  -webkit-transition: 2s all ease-in-out 5s;
  -moz-transition: 2s all ease-in-out 5s;
  -ms-transition: 2s all ease-in-out 5s;
  -o-transition: 2s all ease-in-out 5s;
  transition: 2s all ease-in-out 5s;
}
.fade-carousel .carousel-inner .item.active .hero {
  opacity: 1;
  -webkit-transition: 2s all ease-in-out 5s;
  -moz-transition: 2s all ease-in-out 5s;
  -ms-transition: 2s all ease-in-out 5s;
  -o-transition: 2s all ease-in-out 5s;
  transition: 2s all ease-in-out 5s;
}

.home-banner-sec .carousel .carousel-inner:before {
  content: "";
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0,0,0,0.6);
    position: absolute;
  /* background: linear-gradient(360deg, #5550513d 50%, #5550513d 50%); */
}

.btn.btn-lg {
  padding: 10px 40px;
}
.btn.btn-hero,
.btn.btn-hero:hover,
.btn.btn-hero:focus {
  color: #f5f5f5;
  background-color: #1abc9c;
  border-color: #1abc9c;
  outline: none;
  margin: 20px auto;
}

.fade-carousel .slides .slide-1,
.fade-carousel .slides .slide-2,
.fade-carousel .slides .slide-3 {
  height: 100vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
 .fade-carousel .slides .slide-1 {
  background-image: url('./church-1.jpg');
}
.fade-carousel .slides .slide-2 {
  background-image: url('./church-5.jpg');
}
.fade-carousel .slides .slide-3 {
  background-image: url('./church-4.jpg');
}

.left.carousel-control{
    left: -60px;
}

.right.carousel-control{
    right: -60px;
}

.btn-home-primary {
    background-color: var(--secondary-color);
    border-color:var(--secondary-color);
    display: inline-block;
    padding: 0.65em 1em !important;
    border-bottom-left-radius:0.5em;
    border-top-right-radius:0.5em;
    border-bottom-right-radius:0;
    border-top-left-radius: 0;
    font-size: 1em;
    font-weight: 600;
    color: #333333;
    line-height: 1.6;
    /* margin-right: 1em; */
    width:160px;
}


.btn-home-secondary {
    display: inline-block;
    padding: 0.65em 1em !important;
    border-bottom-left-radius:0.5em;
    border-top-right-radius:0.5em;
    border-bottom-right-radius:0;
    border-top-left-radius: 0;
    font-size: 1em;
    font-weight: 600;
    line-height: 1.6;
    margin-right: 1em;
}

.home-banner-sec .carousel .slider-wrapper.axis-horizontal .slider .slide {
  height: 100vh;
  width: 100%;
  position: relative;
}

.home-banner-sec .carousel .slide img{
  height:100vh;
  object-fit: cover;
}

@media (max-width: 1199.98px) {

}

@media screen and (max-width: 991.98px) {
  .hero {
    width: 485px;
  }
  .overlay-home-banner{
      width: 100%;
  }
  .fade-carousel .carousel-inner .item{
    height: 65vh;
  }
  .fade-carousel .slides .slide-1, .fade-carousel .slides .slide-2, .fade-carousel .slides .slide-3{
      height: 65vh;
  }
  .fade-carousel{
      height: 65vh;
  }
  .fade-carousel .carousel-indicators {
    left: 50%;
}

.stn-live-video .nav-tabs.home-sec-tab >li{
    width: 33.33%;
}
.hero {
    left:50%;
    text-align: center;
  }
  .hero h3{
      max-width: unset;
  }
  .right.carousel-control {
    right: 0px;
}
.left.carousel-control {
    left: 0;
}

.notification-bell {
    
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;

}
}
@media screen and (max-width: 767.98px) {
  .home-banner-sec .carousel .slider-wrapper.axis-horizontal .slider .slide {
    height: 50vh;
  }
  .hero h1 {
    font-size: 2em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .livetv-view .desc{
    padding-bottom: 1em;
  }
  .user-img {
    padding-bottom: 0.5em;
  }
  .hero {
    padding: 0 2em;
    left: 50%;
    text-align: center;
    width: 100%;
    top: 180px;
  }

  .hero h4 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .btn-home-primary{
      margin-bottom: 1em;
  }
  .overlay-home-banner{
      width: 100%;
  }
  .fade-carousel .carousel-inner .item{
    height: 65vh;
  }
  .fade-carousel .slides .slide-1, .fade-carousel .slides .slide-2, .fade-carousel .slides .slide-3{
      height: 65vh;
  }
  .fade-carousel{
      height: 65vh;
  }
  .fade-carousel .carousel-indicators {
    left: 50%;
}

.btn-home-primary{
    margin-right: 0;
}

.btn-home-secondary{
    margin-right:0;
}
.stn-live-video .nav-tabs.home-sec-tab >li{
    width: 100%;
}

.stn-live-video .card{
    padding: 20px 30px 20px 30px;
}
}

.user-profile h4{
  color:#fff;
}

.user-profile h5{
  color:#828282;
}

.section-title{
  font-size: 1.5em;
  margin-bottom: 0;
  color: var(--secondary-color);
  font-weight: 600;
  margin-top: 0;
}

.top-padding-sec{
 padding: 2em;
}

.top-margin-sm{
  margin-top: 2em;
}